<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.FlagName}}</td>
                            <td><v-icon medium :color="item.FlagColor">fa-tag</v-icon>{{item.FlagColor}}</td>
                            <td>{{item.FlagType | display_value(flag_types)}}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">edit</v-icon>
                                <v-icon medium @click="deleteItem(item.ID)">delete</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import _ from "lodash";

export default {
    data() {
        let dataType = configs.DATA_TYPE;
        return {            
            headers: [
                {
                    text: "Hashtag",
                    value: "FlagName",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Màu sắc",
                    value: "FlagColor",
                    dataType: dataType["String"]
                },
                {
                    text: "Loại tag",
                    value: "FlagType",
                    filterable: true,
                    values: configs.FLAG_TYPE_LIST,
                    dataType: dataType["Enum"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            flag_types: configs.FLAG_TYPE_LIST,
            pagination: { ...configs.PAGINATION, sortBy: ["ID"]},
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "flag_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: mapState({
        items: state => state.flag.all.data,
        total_rows: state => state.flag.all.total,
        loading: state => state.flag.loading,
        currentFlag: state => state.flag.selected
    }),
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("flag/getList", param);
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "FlagDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết hashtag (#${id})`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        createData(username = '') {
            this.$router.push({
                name: 'FlagDetail',
                params: {
                Pid: 'tao-moi',
                title: `Tạo mới hashtag`
                }
            });
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("flag/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
